import React, { useEffect } from 'react'
import WeCreateLayout from '@layouts/WeCreateLayout'

import CookieConsent from '@common/CookieConsent'
import PageScrollHorizontalProgressBar from '@common/PageScrollHorizontalProgressBar'
import ImprintContent from '@common/contents/imprint/ImprintContent'

const ImprintPageContainer = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])
  return (
    <>
      <WeCreateLayout>
        <PageScrollHorizontalProgressBar />
        <ImprintContent />
      </WeCreateLayout>

      {/* USE COOKIES */}
      <CookieConsent />
    </>
  )
}

export default ImprintPageContainer
