import React from 'react'
import styled from 'styled-components'
import DisclamerPagesCommonContainer from '@containers/DisclamerPagesCommonContainer'

const ImprintContentWrapper = styled.div`
  width: 70%;
  margin: 50px auto 0 auto;
  ${props => props.theme.media.lessThan('mobile')`
    width: 95%;
  `}
`

const Title = styled.h1`
  font-weight: bold;
  line-height: 50px;
  text-align: center;
  font-size: 50px;
  margin: 80px 0;
  text-transform: capitalize;
  ${props => props.theme.media.lessThan('tablet')`
    font-size: 40px;
  `}
  ${props => props.theme.media.lessThan('mobile')`
    font-size: 30px;
  `}
  ${props => props.theme.media.lessThan('350px')`
    font-size: 25px;
  `}
`

const TextBlock = styled.p`
  font-size: 15px;
  line-height: 24px;
  text-align: left;
  margin: 30px 0;
  ${props => props.theme.media.lessThan('mobile')`
    font-size: 13px;
  `}
`

const Link = styled.a`
  color: ${props => props.theme.color.red};
  text-decoration: underline;
  &:hover {
    opacity: 0.7;
  }
  &:active,
  &:focus,
  &:visited {
    color: ${props => props.theme.color.red};
    text-decoration: underline;
  }
`
const GrayLink = styled(Link)`
  color: inherit;
`

const ImprintContent = () => {
  return (
    <ImprintContentWrapper>
      <DisclamerPagesCommonContainer>
        <Title>Impressum</Title>
        <TextBlock>
          Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und
          Offenlegungspflicht laut §25 Mediengesetz.
        </TextBlock>
        <TextBlock>
          <b>weCreate Data GmbH</b> <br />
          Obere Augartenstraße 2/24, <br />
          1020 Wien, Österreich
        </TextBlock>
        <TextBlock>
          <b>Unternehmensgegenstand</b>: IT Dienstleistungen <br /> <b>UID-Nummer:</b> ATU73053839{' '}
          <b>Firmenbuchnummer:</b> FN 482162 m <br />
          <b>Firmenbuchgericht:</b> Wien
        </TextBlock>
        <TextBlock>
          Bitte nutzen Sie unser Kontaktformular, um mit uns in Verbindung zu treten. <br /> E-Mail:{' '}
          <Link href='mailto:artists@wecreate.media'>artists@wecreate.media</Link>
        </TextBlock>
        <TextBlock>
          <b>Aufsichtsbehörde/Gewerbebehörde:</b> Bezirkshauptmannschaft Wien <br /> <b>Verleihungsstaat:</b> Österreich
        </TextBlock>
        <TextBlock>
          <b>Geschäftsführer:</b> <br /> Johannes Ruisinger
        </TextBlock>
        <TextBlock>
          <b>Beteiligungsverhältnisse:</b> <br /> Gesellschafter Hashtag Deep 24/7 GmbH 67,33%, Etruvian Ltd. 32,67%
        </TextBlock>
        <TextBlock>
          <b>Quelle:</b> <br /> Erstellt mit dem Impressum Generator von firmenwebseiten.at in Kooperation mit
          ostheimer.at
        </TextBlock>

        <TextBlock>
          <b>EU-Streitschlichtung:</b> <br />
          Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie
          über die Online-Streitbeilegungsplattform (OS-Plattform) informieren. Verbraucher haben die Möglichkeit,
          Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission unter{' '}
          <GrayLink href='http://ec.europa.eu/odr' target='_blank'>
            http://ec.europa.eu/odr
          </GrayLink>{' '}
          zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum. Wir möchten Sie
          jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teilzunehmen. Haftung für Inhalte dieser Webseite: Wir entwickeln die Inhalte
          dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen. Leider
          können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite übernehmen, speziell für jene
          die seitens Dritter bereitgestellt wurden. Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen,
          bitte wir Sie uns umgehend zu kontaktieren, Sie finden die Kontaktdaten im Impressum. Haftung für Links auf
          dieser Webseite: Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich
          sind. Haftung für verlinkte Websites besteht laut § 17 ECG für uns nicht, da wir keine Kenntnis rechtswidriger
          Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links
          sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden. Wenn Ihnen rechtswidrige Links auf
          unserer Website auffallen, bitte wir Sie uns zu kontaktieren, Sie finden die Kontaktdaten im Impressum.
          Urheberrechtshinweis: Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem
          Urheberrecht. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite
          rechtlich verfolgen. Bildernachweis: Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich
          geschützt. Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen: weCreate Data GmbH
        </TextBlock>
      </DisclamerPagesCommonContainer>
    </ImprintContentWrapper>
  )
}

export default ImprintContent
