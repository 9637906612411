import React from 'react'

import WeCreatePageSeoContainer from '@containers/WeCreatePageSeoContainer'
import ImprintPageContainer from '@containers/ImprintPageContainer'

const IndexPage = () => (
  <>
    {/* SEO */}
    <WeCreatePageSeoContainer />
    <ImprintPageContainer />
  </>
)

export default IndexPage
